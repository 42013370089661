import { useDraggable } from '@dnd-kit/core';
import { cloneElement } from 'react';

const DraggableTab = ({
  ele,
  index,
  children,
  isOver,
  dragOverlay,
  disabled,
}) => {
  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useDraggable({
      id: ele._id ? ele._id : `tab-${index}`,
      data: { ele, index },
      disabled: disabled,
    });

  const style =
    transform && !disabled
      ? {
          transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
          zIndex: isDragging ? 1000 : 'auto',
          position: isDragging ? 'relative' : 'static',
        }
      : undefined;

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
      data-id={ele._id || `tab-${index}`}
    >
      {cloneElement(children, {
        isOver,
        dragOverlay,
        className: `${children.props.className} ${
          isOver && dragOverlay?.isCombining && !isDragging
            ? '!bg-soft-peach dark:!bg-manchester-brown'
            : ''
        }`,
      })}
    </div>
  );
};

export default DraggableTab;
