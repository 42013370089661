import { create } from 'zustand';
// Constants
const MESSAGE_ACTIONS = {
  ADD: 'add',
  REMOVE: 'remove',
};

const MERGE_STATES = {
  HIGHLIGHT: 'highlight',
  OPERATION: 'operation',
  MERGE: 'merge',
};

const useSmartMergeStore = create((set, get) => ({
  // States
  active: false,
  item: null,
  messages: [],
  isMinimized: false,
  activeTab: MERGE_STATES.HIGHLIGHT,
  highlights: {},
  mergeItem: null,
  formattedHighlights: '',

  // Functions
  setActive: (active) => {
    set(() => ({
      active,
      item: null,
      messages: [],
      isMinimized: false,
      activeTab: MERGE_STATES.HIGHLIGHT,
      highlights: {},
      mergeItem: null,
      formattedHighlights: '',
    }));
  },
  setItem: (item) => {
    set(() => ({
      item,
    }));
  },
  updateMessages: (message, type) => {
    switch (type) {
      case MESSAGE_ACTIONS.ADD:
        set((state) => ({
          messages: state.messages.includes(message)
            ? state.messages
            : [message, ...state.messages],
        }));
        break;
      case MESSAGE_ACTIONS.REMOVE:
        const { [message]: deletedHighlight, ...remainingHighlights } =
          get().highlights;
        set((state) => ({
          highlights: remainingHighlights,
          messages: state.messages.filter((id) => id !== message),
          // ...(state.messages.length == 1 && { active: false, item: null }),
        }));
        break;
      default:
        break;
    }
  },
  setMinimized: (isMinimized) => {
    set(() => ({
      isMinimized,
    }));
  },
  setActiveTab: (activeTab) => {
    set(() => ({
      activeTab,
    }));
  },
  setHighlights: (messageId, highlights) => {
    set(() => ({
      highlights: {
        ...get().highlights,
        [messageId]: highlights,
      },
    }));
  },
  setFormattedHighlights: () => {
    const { highlights, item } = get();
    let formattedText = '';

    Object.keys(highlights).forEach((messageId) => {
      const message = item.find((m) => m._id == messageId);

      formattedText += `### ${message.model}\n`;
      highlights[messageId].forEach((highlight) => {
        formattedText += `- ${highlight.content}\n`;
      });
      formattedText += '\n';
    });
    set(() => ({
      formattedHighlights: formattedText,
    }));
  },
  setMergeItem: (mergeItem) => {
    set(() => ({
      mergeItem,
    }));
  },
  newMerge: () => {
    set(() => ({
      messages: [],
      activeTab: MERGE_STATES.HIGHLIGHT,
      highlights: {},
      mergeItem: null,
      formattedHighlights: '',
    }));
  },
}));

export default useSmartMergeStore;

export { MESSAGE_ACTIONS, MERGE_STATES };
