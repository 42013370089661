import { useEffect, useRef, useState } from 'react';
// State
import useThemeStore from 'Theme/store';
import useTextAreaFocus from './state';
// Styles
import { cn } from 'utils/styles';
// Components
import { Box, IconButton } from '@mui/material';
import Editor from 'react-simple-code-editor';
// Icons
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '@mui/material';

import {
  ExclamationTriangleIcon,
  MicrophoneIcon,
  PaperAirplaneIcon,
  PaperClipIcon,
  LightBulbIcon,
  StopIcon,
  ClipboardDocumentIcon,
} from '@heroicons/react/24/outline';
import FileUpload, { onChangeFile } from '../../../Components/Forms/FileUpload';
import useSmartModeStore from '../../../Features/SmartMode/store';
import CapabilitiesIcon from '../../../Theme/icons/capabilitiesIcon';

const TextAreaHightlight = ({
  value,
  onChange,
  triggerAnimation,
  highlightWords,
  autoFocus,
  disabled,
  onKeyDown,
  maxRows,
  minRows,
  placeholder = `Chat here or type '/' for prompt templates`,
  classNameContainer,
  classNameMainContainer,
  children,
  withoutMaxHeight,
  warningAction,
  onInteraction,
  models,
  setShowFileDialog,
  setShowCapabilitiesDialog,
  startRecording,
  stopRecording,
  onSend,
  inputWarning = false,
  rtlDir = true,
  noActions = false,
  borderRadious = '14px',
  color,
  checkSmartMode = true,
  onCopy = null,
}) => {
  const [maxHeight, setMaxHeight] = useState(false);

  const mainContainerRef = useRef(null);
  const containerRef = useRef(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  // const [showCapabilitiesDialog, setShowCapabilitiesDialog] = useState(false);
  const { smartMode } = useSmartModeStore();

  function escapeHTML(str) {
    const map = {
      '<': '&lt;',
      '>': '&gt;',
      '&': '&amp;',
      '"': '&quot;',
      "'": '&apos;',
    };

    // eslint-disable-next-line no-useless-escape
    return str.replace(/[\<\>\&\"\']/g, (match) => map[match]);
  }

  // !!IMPORTANT Be careful with this function, it affect the styles of prompt templates
  const highlight = (code) => {
    let highlightedCode = code;

    highlightedCode = highlightedCode.replace(/<[^>]+>/g, (match) =>
      escapeHTML(match)
    );

    highlightWords.forEach((highlightConfig) => {
      const { highlight, className, prefix, suffix } = highlightConfig;
      if (highlight && className) {
        highlightedCode = highlightedCode.replace(
          highlight,
          `<span class="${className}">${prefix ?? ''}$1${suffix ?? ''}</span>`
        );
      }
    });

    return highlightedCode;
  };

  // Focus management
  const { focusRequest, blur } = useTextAreaFocus();
  function focusEditor() {
    containerRef.current._input.focus();
    blur();
  }

  useEffect(() => {
    if (autoFocus || focusRequest) focusEditor();
  }, [autoFocus, focusRequest]);

  // Adjust container height
  useEffect(() => {
    adjustContainerHeight();
  }, [value, highlightWords, minRows, maxRows]);

  const triggerSendAnimation = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setIsAnimating(false);
    }, 10000);
  };

  useEffect(() => {
    if (triggerAnimation) {
      triggerSendAnimation();
    }
  }, [triggerAnimation]);

  const handleChange = (e) => {
    onChange(e);
    adjustContainerHeight();
  };

  const adjustContainerHeight = () => {
    const textareaRef = containerRef.current._input;
    const textareaContainerRef = mainContainerRef.current;
    if (textareaRef) {
      // Calculate dynamic maxHeight based on viewport height
      const viewportHeight = window.innerHeight;
      const dynamicMaxHeight = Math.min(
        Math.max(viewportHeight * 0.4, 150), // 40% of viewport height, minimum 150px
        500
      );

      textareaContainerRef.style.maxHeight = `${dynamicMaxHeight}px`;
      textareaContainerRef.style.overflowY = 'scroll';
      setMaxHeight(true);
    }
  };

  // Set spellcheck and other attributes on each input instance
  useEffect(() => {
    const inputElements = mainContainerRef?.current?.querySelectorAll(
      '.npm__react-simple-code-editor__textarea'
    );

    if (inputElements) {
      for (const inputElement of inputElements) {
        inputElement.autocomplete = 'on';
        inputElement.autocorrect = 'on';
        inputElement.spellcheck = true;
      }
    }
  }, []);

  const { theme: themeValue } = useThemeStore();

  const navigate = useNavigate();

  const handleNavigation = (e) => {
    e.preventDefault();
    const path = e.currentTarget.getAttribute('href');
    navigate(path);

    setTimeout(() => {
      const targetSection = document.querySelector('#wordcapLimitsSection');
      if (targetSection) {
        targetSection.scrollIntoView({ behavior: 'smooth' });
        targetSection.focus();
      }
    }, 300);
  };

  const noVision = () => {
    return models?.every((model) => model.type != 'vision');
  };

  const [fileSelected, setFileSelected] = useState([]);

  async function handlePaste(e) {
    const items = e.clipboardData.items;
    const files = [];
    const textItems = [];

    // Primero procesa los archivos de imagen
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') !== -1) {
        const blob = items[i].getAsFile();
        const fileName = `image_${new Date()
          .toISOString()
          .replace(/[:.]/g, '-')}.png`;
        const fileWithMetadata = new File([blob], fileName, {
          type: 'image', // Asegúrate de especificar el tipo correcto
        });
        files.push(fileWithMetadata);
      }
    }

    if (files.length > 0) {
      e.preventDefault();
      if (noVision()) {
        toast(
          'You must select a model that supports images as input, such as GPT-4o',
          {
            icon: '😱',
          }
        );
        return;
      }

      const simulatedEvent = {
        target: {
          files: files,
        },
      };
      await onChangeFile(simulatedEvent, true);
      setFileSelected((prevFiles) => [...prevFiles, ...files]);
    } else {
      for (let j = 0; j < items.length; j++) {
        if (items[j].kind === 'string') {
          textItems.push(
            new Promise((resolve) => {
              items[j].getAsString((str) => {
                const filteredStr = str
                  .replace(
                    /<img[^>]*src=["']data:image\/[^'"]*["'][^>]*>/gi,
                    ''
                  )
                  .replace(/<\/?[^>]+(>|$)/g, '');
                resolve(filteredStr);
              });
            })
          );
        }
      }
    }
  }

  const [dragActive, setDragActive] = useState(false);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const allowedFileTypes = [
    'audio/mpeg',
    'video/mp4',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'text/plain',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/html',
    'text/csv',
    'application/json',
    'image/png',
    'image/jpeg',
    'image/webp',
    'image/gif',
  ];

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    const items = e.dataTransfer.items;
    const files = [];

    for (let i = 0; i < items.length; i++) {
      if (items[i].kind === 'file') {
        const file = items[i].getAsFile();
        if (file) {
          // Verificación específica de tipo de archivo
          if (file.type.startsWith('image/')) {
            const fileName = `image_${new Date()
              .toISOString()
              .replace(/[:.]/g, '-')}.${file.type.split('/')[1]}`;
            const fileWithMetadata = new File([file], fileName, {
              type: 'image',
            });

            files.push(fileWithMetadata);
          } else if (allowedFileTypes.includes(file.type)) {
            files.push(file);
          }
        }
      }
    }

    if (files.length > 0) {
      if (files.some((file) => file.type.startsWith('image')) && noVision()) {
        toast(
          'You must select a model that supports images as input, such as GPT-4o',
          {
            icon: '😱',
          }
        );
        return;
      }

      const simulatedEvent = {
        target: {
          files: files,
        },
      };
      await onChangeFile(simulatedEvent, true);
      setFileSelected((prevFiles) => [...prevFiles, ...files]);
    } else {
      toast('Unsupported file type', {
        icon: '⚠️',
      });
    }
  };

  useEffect(() => {
    const handleWindowDragEnd = (e) => {
      setDragActive(false);
    };

    window.addEventListener('dragend', handleWindowDragEnd);
    window.addEventListener('drop', handleWindowDragEnd);

    return () => {
      window.removeEventListener('dragend', handleWindowDragEnd);
      window.removeEventListener('drop', handleWindowDragEnd);
    };
  }, []);

  const getLightColor = () => {
    switch (color) {
      case 'secondary':
        return 'border-peachy-maroney';
      default:
        return 'border-nue-blue';
    }
  };

  const getDarkColor = () => {
    switch (color) {
      case 'secondary':
        return 'border-peachy-maroney';
      default:
        return 'border-stargate-shimmer';
    }
  };

  return (
    <Box
      dir={rtlDir && 'rtl'}
      ref={mainContainerRef}
      className={cn(
        `relative rounded-[${borderRadious}] !h-[inherit] notranslate p-[10px] overflow-rtl ${
          themeValue == 'dark'
            ? `overflow-rtl-dark bg-night-black text-crystal-bell ${getDarkColor()}`
            : `overflow-rtl-light bg-white max-md:bg-ghost-white text-raisin-black ${getLightColor()}`
        } border mt-2 transition-all duration-300 ease-in-out font-figtree max-md:border-none`,
        checkSmartMode
          ? {
              'border-purple-dark': themeValue === 'dark' && smartMode !== 'on',
              'border-purple': themeValue !== 'dark' && smartMode !== 'on',
              'border-neon-custom': themeValue !== 'dark' && smartMode === 'on',
              'border-neon-dark-custom':
                themeValue === 'dark' && smartMode === 'on',
              'overflow-visible': true,
              'transform scale-105 opacity-75': dragActive,
              'neon-effect-custom': smartMode === 'on',
            }
          : {},
        classNameMainContainer
      )}
      onDrop={handleDrop}
      onDragOver={handleDrag}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
    >
      <div dir="ltr">
        {dragActive ? (
          <div className="flex items-center justify-center h-full p-4">
            <span
              className={`${
                themeValue == 'dark' ? 'text-palladium' : 'text-cool-gray'
              } text-m font-medium uppercase opacity-75`}
            >
              {noVision()
                ? 'Drag and drop your files here (images not supported)'
                : 'Drag and drop your files here to insert it'}
            </span>
          </div>
        ) : (
          <>
            <div style={{ display: 'none' }}>
              <FileUpload
                type=".png, .jpeg, .jpg, .webp, .gif, .mp3, .mp4, .pdf, .docx, .txt, .pptx, .xlsx, .html, .csv, .json"
                isHidden={true}
                onUploaded={(uploadedFiles) => {
                  setFileSelected(uploadedFiles);
                  onInteraction(uploadedFiles);
                }}
              />
            </div>
            {children}
            <div
              className={`w-full flex ${
                themeValue == 'dark'
                  ? 'text-sonic-silver'
                  : 'text-battleship-gray'
              } ${onCopy && 'relative items-start gap-2'}`}
            >
              {onCopy && (
                <IconButton onClick={onCopy} className="!sticky top-0 left-0">
                  <ClipboardDocumentIcon className="w-5 h-5" />
                </IconButton>
              )}
              <Editor
                ref={containerRef}
                value={value}
                onValueChange={handleChange}
                padding={10}
                onPaste={handlePaste}
                onKeyDown={onKeyDown}
                placeholder={placeholder}
                autoFocus={autoFocus}
                disabled={disabled}
                highlight={highlight}
                className={cn(
                  `flex flex-1 font-figtree notranslate text-sm shadow-sm px-4 py-3 rounded font-regular  ${
                    themeValue == 'dark'
                      ? 'bg-night-black text-crystal-bell dark:bg-night-black dark:text-crystal-bell'
                      : 'bg-white max-md:bg-ghost-white text-raisin-black'
                  }`,
                  {
                    'move-up-and-fade': triggerAnimation && smartMode === 'on',
                  },
                  classNameContainer
                )}
                textareaClassName="bg-transparent w-full h-full !flex !p-0"
                preClassName="!block !p-0"
                autoCorrect="on"
                autoComplete="on"
                spellCheck="true"
              />
              {!noActions && (
                <div class="flex justify-center items-center max-h-5 overflow-visible">
                  <>
                    {isRecording ? (
                      <Tooltip title="Finish recording" arrow placement="top">
                        <StopIcon
                          className="max-md:hidden sticky top-0 w-5 h-5 cursor-pointer select-none mr-2 text-red-500"
                          onClick={() => {
                            stopRecording();
                            setIsRecording(false);
                          }}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Record a prompt" arrow placement="top">
                        <MicrophoneIcon
                          className="max-md:hidden sticky top-0 w-5 h-5 cursor-pointer select-none mr-2"
                          onClick={() => {
                            startRecording();
                            setIsRecording(true);
                          }}
                        />
                      </Tooltip>
                    )}
                    <Tooltip title="Attach a file" arrow placement="top">
                      <PaperClipIcon
                        className="max-md:hidden sticky top-0 w-5 h-5 cursor-pointer select-none scale-x-[-1] rotate-45 mr-2"
                        onClick={() => setShowFileDialog(true)}
                      />
                    </Tooltip>
                    <Tooltip title="Select capabilities" arrow placement="top">
                      <button
                        onClick={() => {
                          setShowCapabilitiesDialog(true);
                        }}
                        className="max-md:hidden sticky top-0 flex items-center justify-center focus:outline-none h-5"
                      >
                        <CapabilitiesIcon className="w-5 h-5 cursor-pointer select-none" />
                      </button>
                    </Tooltip>
                    {value?.length > 0 && (
                      <div
                        className="flex sticky top-0 h-6.5 bg-violet-blue text-soft-white justify-center items-center cursor-pointer select-none rounded-lg pl-[6px] pr-[2px] pb-[6px] pt-[2px] ml-2 mt-1"
                        onClick={onSend}
                      >
                        <PaperAirplaneIcon className="w-5 h-5 -rotate-45" />
                      </div>
                    )}
                  </>
                </div>
              )}
            </div>
            {/* {maxHeight && (
              <div className="max-md:hidden sticky bottom-0 flex justify-end w-full">
                <ArrowsPointingOutIcon
                  className={`w-6 h-6 cursor-pointer select-none ${
                    themeValue == 'dark'
                      ? 'text-stargate-shimmer'
                      : 'text-violet-blue'
                  }`}
                />
              </div>
            )} */}
            {inputWarning && (
              <div className="sticky mt-2 bottom-2 left-1 flex px-2 py-1 bg-nue-blue text-white text-xs font-medium gap-2 rounded w-fit">
                <ExclamationTriangleIcon className="w-4 h-4" />
                <div className="select-none">
                  Attention: You've reached the word limit for this request. You
                  can start a{' '}
                  <span
                    className="underline cursor-pointer"
                    onClick={warningAction}
                  >
                    new chat
                  </span>{' '}
                  or{' '}
                  <a
                    className="underline"
                    href="/user-settings"
                    onClick={handleNavigation}
                  >
                    adjust wordcap limits
                  </a>{' '}
                  in your settings.
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </Box>
  );
};
export default TextAreaHightlight;
