function SvgComponent(props) {
  return (
    <svg
      width="34"
      height="35"
      viewBox="0 0 34 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect y="0.894531" width="34" height="34" rx="10" fill="inherit" />
      <path
        d="M11.2854 21.1801C11.2854 18.0242 13.8438 15.4658 16.9997 15.4658C20.1556 15.4658 22.714 18.0242 22.714 21.1801C22.714 24.336 20.1556 26.8944 16.9997 26.8944C13.8438 26.8944 11.2854 24.336 11.2854 21.1801Z"
        stroke="white"
        stroke-width="1.5"
      />
      <path
        d="M7.99976 15.6088C7.99976 12.4529 10.5581 9.89453 13.714 9.89453C16.87 9.89453 19.4283 12.4529 19.4283 15.6088C19.4283 18.7647 16.87 21.3231 13.714 21.3231C10.5581 21.3231 7.99976 18.7647 7.99976 15.6088Z"
        stroke="white"
        stroke-width="1.5"
      />
      <path
        d="M14.5713 15.6088C14.5713 12.4529 17.1297 9.89453 20.2856 9.89453C23.4415 9.89453 25.9999 12.4529 25.9999 15.6088C25.9999 18.7647 23.4415 21.3231 20.2856 21.3231C17.1297 21.3231 14.5713 18.7647 14.5713 15.6088Z"
        stroke="white"
        stroke-width="1.5"
      />
    </svg>
  );
}

export default SvgComponent;
