/* eslint-disable no-console */
/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
import {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
// Plugin's

import katex from 'katex'; // Import KaTeX to render mathematical equations
import 'katex/dist/katex.min.css'; // Import CSS styles for KaTeX
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
// Components
import { Tooltip } from '@mui/material';
import useThemeStore from 'Theme/store';
import toast from 'react-hot-toast';
import { HighlightedText } from './HighlightedText';
import { MemoizedReactMarkdown } from './Markdown';
import { MarkdownComponents } from './Markdown.components';
import { MemoizedOriginalPrompt } from './OriginalPrompt';
import ImageMaskEditorModal from './ImageMaskEditorModal';
// Icons
import {
  ArrowDownTrayIcon,
  HeartIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/solid';
import useChatStore from 'Components/Chat/store';
import useSmartMergeStore, { MERGE_STATES } from 'Features/SmartMerge/store';
import Modal from 'react-modal';

const ContentMessage = memo(
  forwardRef(
    (
      {
        message,
        messageId,
        userType,
        onFavoriteFile,
        onOpenFull,
        originalPrompt,
        expanded = true,
        optimizing,
        highlight = false,
        highlights = [],
        images,
        store,
        renderedFromShareChatPage,
        isEditable,
        onSend,
        loading,
        capability,
        functionCapability,
      },
      ref
    ) => {
      const selectedChat = useChatStore((state) => state.selectedChat);

      const mergeActive = useSmartMergeStore((state) => state.active);
      const mergeState = useSmartMergeStore((state) => state.activeTab);
      const newHighlights = useSmartMergeStore(
        (state) => state.highlights[messageId] || []
      );
      const setNewHighlights = useSmartMergeStore(
        (state) => state.setHighlights
      );

      const { theme } = useThemeStore();

      const [isRendering, setIsRendering] = useState(false);
      const [isOpen, setIsOpen] = useState(false);
      const [modalImage, setModalImage] = useState(null);
      const [maskEditorOpen, setMaskEditorOpen] = useState(false);
      const [maskEditorImage, setMaskEditorImage] = useState(null);

      const [localOriginalPrompt, setLocalOriginalPrompt] = useState(
        originalPrompt || ''
      );

      useEffect(() => {
        if (originalPrompt !== null && originalPrompt !== undefined) {
          setLocalOriginalPrompt(originalPrompt);
        }
      }, [originalPrompt]);

      /**
       * Renders mathematical equations using KaTeX.
       * @param {string} equation - The equation to render.
       * @param {boolean} displayMode - Block mode (true) or inline mode (false).
       * @returns {string} - The rendered equation in HTML.
       */
      const renderEquation = (equation, displayMode = false) => {
        try {
          const className = displayMode ? 'latex-block' : 'latex-inline';
          return `<span class="${className}">${katex.renderToString(equation, {
            throwOnError: false,
            displayMode,
          })}</span>`;
        } catch (error) {
          console.error('KaTeX Error:', error);
          return `<span style="color:#cc0000">Error in equation: ${equation}</span>`;
        }
      };

      const getMessage = (message) => {
        if (typeof message == 'string') {
          return processing(message);
        }

        if (message.length === 1 && message[0].type === 'text') {
          return processing(message[0].text);
        }

        const parseMessage = message.map((frg, index) => {
          switch (frg.type) {
            case 'image_url':
              return (
                <div
                  key={index}
                  className={`max-h-80 w-full flex justify-center items-center`}
                >
                  <div
                    className={`max-h-80 h-fit cursor-pointer relative ${
                      !frg.loading ? 'w-fit' : 'w-full'
                    }`}
                    onClick={() => onOpenFull(getImgTag(frg, true))}
                  >
                    {frg.loading ? (
                      <div
                        className={`rounded-[14px] w-full h-80 flex justify-center items-center  ${
                          theme == 'dark' ? 'bg-ship-grey' : 'bg-lavender'
                        }`}
                      >
                        <img
                          className="h-9"
                          key={Math.random()}
                          src="/gifs/straico_loader.gif"
                          alt="img ref"
                        />
                      </div>
                    ) : (
                      getImgTag(frg)
                    )}
                    {frg.no_actions || userType == 'user' ? (
                      ''
                    ) : (
                      <div className="absolute p-2 bottom-0 right-0 rounded-b-[14px] w-full bg-gradient-to-t from-raisin-black to-transparent">
                        <div className="relative flex gap-2 justify-end">
                          <div className="relative group">
                            <Tooltip className="hidden absolute font-medium bottom-full right-full group-hover:flex flex-col items-center whitespace-nowrap">
                              <span className="z-50 p-2 text-base leading-none text-raisin-black bg-lavender bg-opacity-50 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                                Download image
                              </span>
                            </Tooltip>
                            <ArrowDownTrayIcon
                              onClick={(e) =>
                                handleDownload(e, frg.image_url.url)
                              }
                              className="w-5 h-5 font-bold text-white cursor-pointer"
                            />
                          </div>
                          {onFavoriteFile && (
                            <div className="relative group">
                              <Tooltip className="hidden absolute font-medium bottom-full right-full group-hover:flex flex-col items-center whitespace-nowrap">
                                <span className="z-50 p-2 text-base leading-none text-raisin-black bg-lavender bg-opacity-50 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                                  Add image to your attachments
                                </span>
                              </Tooltip>
                              <HeartIcon
                                id={'favorite' + frg._id}
                                onClick={(e) => handleFavorite(e, frg)}
                                className={`w-5 h-5 font-bold ${
                                  frg.favorite ? 'text-nue-blue' : 'text-white'
                                } cursor-pointer`}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            default:
              return processing(frg.text);
          }
        });
        return parseMessage;
      };

      const getImgTag = (frg, maxScreen = false) => {
        return (
          <img
            className={`max-h-${
              maxScreen ? '[calc(100vh_-_2rem)]' : '80'
            } rounded-[14px]`}
            key={Math.random()}
            src={frg.image_url.url}
            alt="img ref"
          />
        );
      };

      const handleDownload = (e, url) => {
        e.stopPropagation();
        const link = document.createElement('a');
        link.href = url;
        link.download = 'image.jpg';
        link.target = '_blanck';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

      const handleFavorite = (e, item) => {
        e.stopPropagation();
        if (!item.favorite) {
          let url = item.image_url.url;
          onFavoriteFile({
            url: url,
            name: url.substring(url.lastIndexOf('/') + 1),
            size: null,
            enabled: true,
            type: 'image',
            imageId: item._id,
            chatId: selectedChat.chat._id,
          });

          const heartIcon = document.getElementById('favorite' + item._id);
          if (heartIcon) {
            heartIcon.classList.remove('text-white');
            heartIcon.classList.add('text-nue-blue');
            item.favorite = !item.favorite;
          }
        } else {
          toast.error('The file is currently in your attachments.');
        }
      };

      const customStylesRegex = /""'\s*([^']*)\s*'""/g;

      const regex_1 = /```"'(.*?)'"```/g;
      const regex_2 = /```""'(.*?)'""```/g;

      const processing = (text) => {
        let formattedMessage = text
          .replace(regex_1, (match) => {
            return `""'${match.replaceAll('"', '').replaceAll("'", '')}'""`;
          })
          .replace(regex_2, (match) => {
            return `""'${match.replaceAll('"', '').replaceAll("'", '')}'""`;
          });

        if (userType === 'user') {
          formattedMessage = formattedMessage.replaceAll(
            customStylesRegex,
            (match) => {
              return `<span id='template'>${match
                .replaceAll('"', '')
                .replaceAll("'", '')}</span>`;
            }
          );
        }

        // Render mathematical equations detected by their delimiters
        formattedMessage = formattedMessage
          .replace(/\\\[([^\]]+?)\\\]/g, (_, equation) =>
            renderEquation(equation, true)
          )
          .replace(/\\\(([^\)]+?)\\\)/g, (_, equation) =>
            renderEquation(equation, false)
          );

        return formattedMessage;
      };

      const renderMessageContent = (message) => {
        if (!message) {
          return;
        }
        const messageContent = getMessage(message);
        const processedContent = processTextWithHighlights(
          messageContent,
          highlights.length ? highlights : newHighlights
        );

        if (typeof messageContent === 'string') {
          return (
            <MemoizedReactMarkdown
              className="prose break-words dark:prose-invert prose-p:leading-relaxed markdown prose-pre:p-0 text-base"
              remarkPlugins={[remarkGfm, remarkMath]}
              rehypePlugins={[rehypeRaw]}
              components={MarkdownComponents}
            >
              {processedContent}
            </MemoizedReactMarkdown>
          );
        } else {
          return (
            <div
              className={`py-2 grid gap-2 justify-items-center ${
                messageContent.length > 1 && 'grid-cols-2'
              }`}
            >
              {messageContent}
            </div>
          );
        }
      };

      const openModal = (url) => {
        setModalImage(url);
        setIsOpen(true);
      };

      const closeModal = () => {
        setModalImage(null);
        setIsOpen(false);
      };

      const openMaskEditor = (url) => {
        setMaskEditorImage(url);
        setMaskEditorOpen(true);
      };

      const closeMaskEditor = () => {
        setMaskEditorImage(null);
        setMaskEditorOpen(false);
      };

      const [processingImages, setProcessingImages] = useState(new Set());

      const [localCapabilities, setLocalCapabilities] = useState(() => {
        const savedCapabilities = localStorage.getItem('capabilities');
        return savedCapabilities ? JSON.parse(savedCapabilities) : [];
      });

      const getImageState = (func, msg) => {
        if (!localCapabilities || localCapabilities.length === 0) {
          return null;
        }
        if (func && !msg) {
          if (loading && capability?.name !== 'Browsing') {
            return (
              loading && (
                <div
                  className={`rounded-[14px] w-full h-80 flex justify-center items-center relative overflow-hidden ${
                    theme == 'dark' ? 'bg-ship-grey' : 'bg-lavender'
                  }`}
                  style={{
                    background: `linear-gradient(
                    to right,
                    ${theme === 'dark' ? '#333333' : '#e5e7eb'} 0%,
                    ${theme === 'dark' ? '#555555' : '#ffffff'} 50%,
                    ${theme === 'dark' ? '#333333' : '#e5e7eb'} 100%
                  )`,
                    backgroundSize: '200% 100%',
                    animation: 'shimmer 2s infinite',
                  }}
                ></div>
              )
            );
          }
        }
      };

      const renderImages = () => {
        const handleFavoriteImage = async (e, image) => {
          e.stopPropagation();

          if (processingImages.has(image.url)) return;

          if (!image.favorite) {
            setProcessingImages((prev) => new Set(prev).add(image.url));

            onFavoriteFile({
              url: image.url,
              name: image.url.substring(image.url.lastIndexOf('/') + 1),
              size: null,
              enabled: true,
              type: 'image',
              chatId: selectedChat.chat._id,
            });
            image.favorite = true;

            setProcessingImages((prev) => {
              const updated = new Set(prev);
              updated.delete(image.url);
              return updated;
            });
          } else {
            toast.error('The file is currently in your attachments.');
          }
        };

        if (!images && loading && capability) {
          return (
            <div
              className={`rounded-[14px] w-full h-80 flex justify-center items-center relative overflow-hidden ${
                theme == 'dark' ? 'bg-ship-grey' : 'bg-lavender'
              }`}
              style={{
                background: `linear-gradient(
                to right,
                ${theme === 'dark' ? '#333333' : '#e5e7eb'} 0%,
                ${theme === 'dark' ? '#555555' : '#ffffff'} 50%,
                ${theme === 'dark' ? '#333333' : '#e5e7eb'} 100%
              )`,
                backgroundSize: '200% 100%',
                animation: 'shimmer 2s infinite',
              }}
            ></div>
          );
        }

        if (images && images.length > 0) {
          return (
            <div className="gap-10 max-w-sm mx-auto">
              {images.map((image, index) => (
                <div
                  key={index}
                  className="relative group max-h-90 w-full flex justify-center items-center"
                >
                  <img
                    src={image.url}
                    alt={`Image ${index + 1}`}
                    className="max-h-90 rounded-[14px] cursor-pointer w-full object-cover"
                    onClick={() => {
                      setModalImage(image.url);
                      setIsOpen(true);
                    }}
                    loading="lazy"
                  />

                  {!renderedFromShareChatPage && (
                    <div className="absolute bottom-2 right-2 flex gap-2">
                      <Tooltip title="Download image" placement="top" arrow>
                        <button
                          onClick={(e) => handleDownload(e, image.url)}
                          className="bg-black bg-opacity-50 p-1 rounded-md text-white"
                        >
                          <ArrowDownTrayIcon className="w-5 h-5" />
                        </button>
                      </Tooltip>

                      {onFavoriteFile && (
                        <Tooltip title="Add to favorites" placement="top" arrow>
                          <button
                            id={`favorite-${image.url}`}
                            onClick={(e) => handleFavoriteImage(e, image)}
                            className={`bg-black bg-opacity-50 p-1 rounded-md ${
                              image.favorite ? 'text-nue-blue' : 'text-white'
                            }`}
                          >
                            <HeartIcon className="w-5 h-5 font-bold" />
                          </button>
                        </Tooltip>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          );
        }
        return null;
      };

      /*** Highlight behaviour block  */ /////////////////////////////////////////////////
      const handleTextSelection = () => {
        if (!mergeActive || mergeState != MERGE_STATES.HIGHLIGHT || !highlight)
          return;

        const selection = window.getSelection();
        if (!selection.rangeCount) return;

        const baseText = getMessage(message);
        if (typeof baseText !== 'string') return;

        const selectedText = selection.toString().trim();
        if (!selectedText) return;

        selection.removeAllRanges();
        const lines = selectedText.split(/\r?\n/);
        proccessTextSelection(lines);
      };

      const proccessTextSelection = (selectedText) => {
        const baseText = getMessage(message);
        if (typeof baseText !== 'string') return;

        const selection = [];
        for (let text of selectedText) {
          const { start, end } = getExpandedSelection(baseText, text);
          if (start === -1) continue;

          const textToHighlight = baseText.substring(start, end);
          selection.push({ start, end, text: textToHighlight });
        }
        handleSingleLineSelection(selection);
      };

      const getExpandedSelection = (baseText, selectedText) => {
        let start = baseText.indexOf(selectedText);
        if (start === -1) return { start: -1, end: -1 };

        let end = start + selectedText.length;

        while (start > 0 && !/[\s\n.,!?;:]/.test(baseText[start - 1])) {
          start--;
        }

        while (end < baseText.length && !/[\s\n.,!?;:]/.test(baseText[end])) {
          end++;
        }

        return { start, end };
      };

      const handleSingleLineSelection = (selection) => {
        let updatedHighlights = [...newHighlights];
        for (let sel of selection) {
          const { text, start, end } = sel;
          const existingHighlight = updatedHighlights.find(
            (h) => start >= h.start && end <= h.end
          );

          if (existingHighlight) {
            updatedHighlights = removeHighlight(
              updatedHighlights,
              existingHighlight,
              { start, end }
            );
          } else {
            updatedHighlights = mergeHighlights([
              ...updatedHighlights,
              {
                start,
                end,
                content: text,
              },
            ]);
          }
        }
        setNewHighlights(messageId, updatedHighlights);
      };

      const removeHighlight = (
        currentHighlights,
        existingHighlight,
        selection
      ) => {
        const baseText = getMessage(message);
        return currentHighlights
          .map((h) => {
            if (h === existingHighlight) {
              if (selection.end === h.end) {
                return {
                  start: h.start,
                  end: selection.start,
                  content: baseText.substring(h.start, selection.start),
                };
              }
              return {
                start: selection.end,
                end: h.end,
                content: baseText.substring(selection.end, h.end),
              };
            }
            return h;
          })
          .filter((h) => h.start < h.end);
      };

      const mergeHighlights = (highlights) => {
        if (highlights.length === 0) return [];

        const sorted = highlights.sort((a, b) => a.start - b.start);
        const merged = [];
        let current = { ...sorted[0] };

        for (let i = 1; i < sorted.length; i++) {
          const next = sorted[i];

          if (next.start <= current.end) {
            current.end = Math.max(current.end, next.end);
            current.content = getCurrentContent(
              getMessage(message),
              current.start,
              current.end
            );
          } else {
            merged.push(current);
            current = { ...next };
          }
        }

        merged.push(current);

        return merged;
      };

      const getCurrentContent = (baseText, start, end) => {
        return baseText.substring(start, end);
      };

      const processTextWithHighlights = (text, highlights) => {
        if (!highlights.length) return text;

        const sortedHighlights = highlights.sort((a, b) => a.start - b.start);
        let result = '';
        let lastIndex = 0;

        sortedHighlights.forEach(({ start, end }) => {
          result += text.slice(lastIndex, start);

          const temp = text.slice(start, end);
          const parts = temp.split('\n');

          parts.forEach((part, index) => {
            result += `<span class="bg-peachy-maroney/30">${part}</span>${
              index !== parts.length - 1 ? '\n' : ''
            }`;
          });

          lastIndex = end;
        });

        result += text.slice(lastIndex);

        return result;
      };

      const highlightAll = () => {
        const baseText = getMessage(message);
        proccessTextSelection(baseText);
      };

      const removeHighlights = () => {
        setNewHighlights(messageId, []);
      };

      useImperativeHandle(ref, () => ({
        highlightAll,
        removeHighlights,
      }));
      /*** End highlight behaviour block  */ /////////////////////////////////////////////

      return (
        <div className={`${!expanded && 'line-clamp-2'}`}>
          <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            contentLabel="Image Modal"
            className="flex justify-center items-center"
            overlayClassName="fixed inset-0 bg-black bg-opacity-70 z-[9999]"
            style={{
              content: {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                border: 'none',
                background: 'transparent',
                padding: '0',
                maxWidth: '100%',
                maxHeight: '90vh',
                overflow: 'hidden',
              },
              overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                zIndex: 9999,
              },
            }}
          >
            {modalImage && (
              <img
                src={modalImage}
                alt="Full view"
                className="max-w-full max-h-[90vh] rounded-md"
              />
            )}
          </Modal>

          <ImageMaskEditorModal
            isOpen={maskEditorOpen}
            onRequestClose={closeMaskEditor}
            imageUrl={maskEditorImage}
            store={store}
            onSend={onSend}
          />

          {userType === 'user' ? (
            <>
              <MemoizedOriginalPrompt
                originalPrompt={originalPrompt}
                theme={theme}
                optimizing={optimizing}
              />
              <HighlightedText text={getMessage(message)} />
            </>
          ) : (
            <>
              <div onMouseUp={handleTextSelection}>
                {renderMessageContent(message)}
              </div>
              {renderImages()}
              {getImageState(functionCapability, message)}
            </>
          )}
        </div>
      );
    }
  )
);

export default ContentMessage;
