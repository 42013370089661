import { useEffect, useState, useRef, useMemo } from 'react';
import {
  IconButton,
  Button,
  Tabs,
  Tab,
  Select,
  MenuItem,
  Tooltip,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOffOutlined';
import CachedIcon from '@mui/icons-material/Cached';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { motion } from 'framer-motion';
import useSmartMergeStore, { MERGE_STATES } from 'Features/SmartMerge/store';
import SmartMergeIcon from '../../../../Theme/icons/SmartMergeIcon';
import {
  CheckCircleIcon,
  ChevronUpIcon,
  Cog8ToothIcon,
  PlusCircleIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';
import ModelsIcon from '../../../../Theme/icons/modelsIcon';
import { icons as modelIcons } from '../../../../Theme/icons/modelsIcons/index';
import { AnimatePresence } from 'framer-motion';
import { CurrencyDollarIcon } from '@heroicons/react/24/solid';
import useThemeStore from 'Theme/store';
import { cn } from 'utils/styles';
import { inject, observer } from 'mobx-react';
import ViewPromptSection from 'Components/Chat/TemplateModal/components/ViewPromptSection';
import useChatStore, { SAVE_MERGE } from 'Components/Chat/store';
import toast from 'react-hot-toast';
import { toastReward } from 'utils/toastCall';
import Skeleton from 'utils/Skeleton';
import { get } from 'lodash';
import ModelDialog from 'Components/ModelDialog';
import { MemoizedReactMarkdown } from 'Components/Common/Markdown/Markdown';
import { MarkdownComponents } from 'Components/Common/Markdown/Markdown.components';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';

const customDummy = () => {
  return {
    _id: 'custom',
    prompt_values: {
      value: '\n${responses}',
    },
    desc: 'Write your own instructions for the merge, and choose the model that will do the operation.',
  };
};

const SmartMergeDialog = inject('store')(
  observer(({ store }) => {
    const active = useSmartMergeStore((state) => state.active);
    const setActive = useSmartMergeStore((state) => state.setActive);
    const isMinimized = useSmartMergeStore((state) => state.isMinimized);
    const setMinimized = useSmartMergeStore((state) => state.setMinimized);
    const activeTab = useSmartMergeStore((state) => state.activeTab);
    const setActiveTab = useSmartMergeStore((state) => state.setActiveTab);
    const highlights = useSmartMergeStore((state) => state.highlights);
    const messages = useSmartMergeStore((state) => state.messages);
    const formattedHighlights = useSmartMergeStore(
      (state) => state.formattedHighlights
    );
    const setFormattedHighlights = useSmartMergeStore(
      (state) => state.setFormattedHighlights
    );
    const itemMerging = useSmartMergeStore((state) => state.item);
    const mergeItem = useSmartMergeStore((state) => state.mergeItem);
    const setMergeItem = useSmartMergeStore((state) => state.setMergeItem);
    const newMerge = useSmartMergeStore((state) => state.newMerge);

    const selectedChat = useChatStore((state) => state.selectedChat);
    const addEvent = useChatStore((state) => state.addEvent);

    const { theme } = useThemeStore();

    const viewPromptRef = useRef(null);
    const containerRef = useRef(null);

    const [mergeTools, setMergeTools] = useState([]);
    const [selectedTool, setSelectedTool] = useState('custom');
    const [tool, setTool] = useState(customDummy());
    const [showPrompt, setShowPrompt] = useState(false);
    const [rawPrompt, setRawPrompt] = useState('');
    const [merging, setMerging] = useState(false);
    const [typing, setTyping] = useState(false);
    const [saving, setSaving] = useState(false);
    const [model, setModel] = useState(false);
    const [coins, setCoins] = useState(0);
    const [showModelsDialog, setShowModelsDialog] = useState(false);

    const handleMinimize = () => setMinimized(!isMinimized);
    const handleTabChange = (event, newValue) => {
      setActiveTab(tabContent[newValue].id);
    };
    const handlePrevious = () => {
      const currentIndex = tabContent.findIndex((tab) => tab.id === activeTab);
      if (currentIndex > 0) {
        setActiveTab(tabContent[currentIndex - 1].id);
      }
    };

    useEffect(() => {
      setShowPrompt(false);
      if (active) getMergeTools();
      if (!model) getDefaultModel();
    }, [active]);

    useEffect(() => {
      const tool = getTool(selectedTool);
      setTool(tool);
    }, [selectedTool]);

    useEffect(() => {
      if (tool) {
        const text = tool.prompt_values.value.replace(
          '${responses}',
          formattedHighlights
        );
        calculateCoins(text);
      }
    }, [highlights, tool]);

    useEffect(() => {
      setFormattedHighlights();
    }, [highlights]);

    const getDefaultModel = async () => {
      try {
        const response = await store.api.get(`/merge/model`);
        const model = response.data.model;
        setModel(model);
      } catch (error) {
        console.error(error);
      }
    };

    const getMergeTools = async () => {
      try {
        const response = await store.api.get(`/merge/tools`);
        const tools = response.data.tools;
        setMergeTools(tools);
        if (tools.length) setSelectedTool(tools[0]._id);
      } catch (error) {
        console.error(error);
      }
    };

    const handleChangeTool = (event) => {
      if (viewPromptRef.current)
        setRawPrompt(viewPromptRef.current.getRawPrompt());

      setSelectedTool(event.target.value);
    };

    const getTool = (toolId) => {
      if (toolId == 'custom') {
        return customDummy();
      }

      return mergeTools.find((tool) => tool._id == toolId);
    };

    const scrollToBottom = () => {
      if (containerRef.current) {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }
    };

    const getMergeCard = () => {
      return (
        <div className="w-full h-full flex flex-col font-figtree gap-4">
          <div className="w-full flex gap-2 items-center">
            <div className="text-xs leading-5 font-bold text-peachy-maroney">
              UNIFY RESPONSES:
            </div>
            {mergeItem.model.map((model) => (
              <div className="text-sm leading-5 font-semibold rounded-lg py-[1px] px-2 text-white bg-peachy-maroney">
                {model}
              </div>
            ))}
          </div>
          <div
            ref={containerRef}
            className={`w-full flex-1 scroll-smooth overflow-auto overflow-rtl ${
              theme == 'dark' ? 'overflow-rtl-dark' : 'overflow-rtl-light'
            }`}
          >
            <TypeWriter
              content={mergeItem.data.content}
              onTypingDone={() => setTyping(false)}
              onCharacterTyped={scrollToBottom}
              typingSpeed={7}
            />
          </div>
        </div>
      );
    };

    const getIcon = (icon) => {
      const Icon = get(modelIcons, icon);
      return Icon ? (
        <Icon className="h-6 w-6" />
      ) : (
        <ModelsIcon className="h-6 w-6 stroke-raisin-black dark:stroke-crystal-bell" />
      );
    };

    const tabContent = [
      {
        id: MERGE_STATES.HIGHLIGHT,
        icon: <PlusCircleIcon className="w-5 h-5" />,
        title: 'Select content',
        content: (
          <div className="w-full">
            <div className="w-full text-center my-5 text-agate-violet">
              <p>
                Select the responses and highlight the content you wish to
                include in the merge.
              </p>{' '}
              <p>
                Currently, you have chosen <span className="font-bold">2</span>{' '}
                models for the merge.
              </p>
            </div>
          </div>
        ),
      },
      {
        id: MERGE_STATES.OPERATION,
        icon: <Cog8ToothIcon className="w-5 h-5" />,
        title: 'Choose operation',
        content: (
          <div className="w-full flex justify-between items-start my-4 gap-4">
            <Select
              size="small"
              value={selectedTool}
              onChange={handleChangeTool}
              color="secondary"
              MenuProps={{
                PaperProps: {
                  sx: {
                    borderRadius: '10px',
                    mt: '4px',
                    '& .MuiMenuItem-root': {
                      fontSize: '14px',
                    },
                  },
                },
              }}
              sx={{
                fontFamily: 'Figtree',
                borderRadius: '10px',
                fontSize: '14px',
                background: theme == 'dark' ? '#39383A' : '#FFFFFF',
                '& .MuiSelect-select': { padding: '8px 10px' },
              }}
            >
              {mergeTools.map((tool) => (
                <MenuItem value={tool._id}>{tool.title}</MenuItem>
              ))}
              <MenuItem value="custom">Custom Prompt</MenuItem>
            </Select>
            <div className="flex-1 text-center">{tool.desc}</div>
            {selectedTool === 'custom' ? (
              <div className="flex gap-2 items-center">
                <Tooltip title="Change LLM for this operation" arrow>
                  <div
                    onClick={() => setShowModelsDialog(true)}
                    className={`w-[32px] h-[32px] bg-tranquil-peach hover:bg-tranquil-peach/70 cursor-pointer rounded-[10px] justify-center items-center flex select-none`}
                  >
                    <PencilSquareIcon className="w-5 h-5 text-peachy-maroney" />
                  </div>
                </Tooltip>
                <div
                  className={`flex gap-2 items-center rounded-xl p-2 border-[1.5px] ${
                    theme === 'dark'
                      ? 'bg-lead border-sonic-silver'
                      : 'bg-white border-cool-gray/35'
                  }`}
                >
                  <div className="flex items-center gap-2">
                    {getIcon(model.icon)}
                    <span
                      style={{ paddingTop: '2px' }}
                      className="flex-1 line-clamp-1 text-sm text-agate-violet dark:text-palladium"
                    >
                      {model.name}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                startIcon={
                  showPrompt ? <VisibilityOffIcon /> : <VisibilityIcon />
                }
                sx={{
                  fontSize: '14px',
                  borderRadius: '10px',
                  padding: '8px 14px',
                  height: '32px',
                  color: '#9091A4',
                  borderColor: '#9091A4',
                  '&:hover': {
                    borderColor: '#9091A4',
                  },
                }}
                onClick={() => setShowPrompt(!showPrompt)}
              >
                {showPrompt ? 'Hide prompt' : 'Show prompt'}
              </Button>
            )}
          </div>
        ),
      },
      {
        id: MERGE_STATES.MERGE,
        icon: <CheckCircleIcon className="w-5 h-5" />,
        title: 'Merge responses',
        content: (
          <div className="h-[174px] bg-white rounded-[22px] border-[3px] border-peachy-maroney flex justify-center items-center my-4 px-4 py-5">
            {merging ? <Skeleton /> : <>{mergeItem ? getMergeCard() : <></>}</>}
          </div>
        ),
      },
    ];

    const containerVariants = {
      minimized: {
        scaleY: 0,
        opacity: 0,
        transition: {
          duration: 0.3,
        },
      },
      expanded: {
        scaleY: 1,
        opacity: 1,
        transition: {
          duration: 0.3,
        },
      },
    };

    const getTab = () => {
      return tabContent.find((tab) => tab.id === activeTab);
    };

    const getTabIndex = () => {
      return tabContent.findIndex((tab) => tab.id === activeTab);
    };

    const getHeader = () => {
      return (
        <div className="flex justify-between items-center">
          <div className="text-2xl font-semibold flex gap-3.5">
            <div className="flex items-center justify-center w-[34px] h-[34px] bg-peachy-maroney select-none rounded-xl">
              <SmartMergeIcon className="h-7 w-7 text-white" />
            </div>
            <div>Smart Merge</div>
          </div>

          {!isMinimized && (
            <Tabs
              value={getTabIndex()}
              onChange={handleTabChange}
              centered
              TabIndicatorProps={{
                sx: {
                  height: '3px',
                  bottom: '0px',
                  backgroundColor: '#F2A474',
                },
              }}
              sx={{
                '& .MuiTabs-flexContainer': {
                  gap: '50px',
                },
                '& .MuiTab-iconWrapper': {
                  marginBottom: 0,
                },
              }}
            >
              {tabContent.map((tab) => (
                <Tab
                  label={tab.title}
                  disabled
                  sx={{
                    fontFamily: 'Figtree',
                    fontSize: '20px',
                    fontWeight: 700,
                    textTransform: 'none',
                    lineHeight: '30px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '10px',
                    maxHeight: '40px',
                    minHeight: '40px',
                    padding: '0 8px',
                    '&.Mui-selected': {
                      color: '#F2A474',
                    },
                  }}
                  icon={tab.icon}
                />
              ))}
            </Tabs>
          )}

          <div
            className={`cursor-pointer select-none duration-500 ${
              isMinimized && 'rotate-[180deg]'
            }`}
          >
            <IconButton onClick={handleMinimize}>
              <ChevronUpIcon className="h-5 w-5" />
            </IconButton>
          </div>
        </div>
      );
    };

    const checkHighlights = () => {
      return (
        Object.keys(highlights).length === messages.length &&
        Object.keys(highlights).length !== 0 &&
        Object.values(highlights).every((arr) => arr.length > 0)
      );
    };

    const consditionsByTab = () => {
      switch (activeTab) {
        case MERGE_STATES.HIGHLIGHT:
          return checkHighlights() && messages.length > 1;
        default:
          return true;
      }
    };

    const onMerge = async (regenerate = false) => {
      setMerging(true);
      setActiveTab(MERGE_STATES.MERGE);

      if (viewPromptRef.current)
        setRawPrompt(viewPromptRef.current.getRawPrompt());

      try {
        const response = await store.api.post(`/ai/chat/merge`, {
          hash: selectedChat?.chat?._id,
          idTool: selectedTool,
          highlights: highlights,
          prompt: viewPromptRef.current
            ? viewPromptRef.current.getRawPrompt()
            : rawPrompt,
          coins: coins,
          ...(selectedTool == 'custom' ? { modelId: model._id } : {}),
        });

        setMerging(false);
        if (!response.data.success) {
          if (
            response.data.error === 'no_coins' ||
            response.data.error === 'missing_coins'
          ) {
            toastReward({
              title: 'Missing coins',
              message: 'Recharge your account on the profile page',
              linkText: "It's quick and easy. Click here!",
              linkUrl: '/my-profile/pricing',
              image: '/gifs/coin.gif',
              time: 10000,
            });
            if (!regenerate) setActiveTab(MERGE_STATES.OPERATION);
          }
        } else {
          const merge = response.data.merge;
          setMergeItem(merge);
          setTyping(true);
        }
      } catch (error) {
        console.error(error);
        if (!regenerate) setActiveTab(MERGE_STATES.OPERATION);
        setMerging(false);
        toast.error('Failed to merge responses');
      }
    };

    const calculateCoins = (text) => {
      if (!model) return;

      let words = store.countWords(text);

      const pricing = model.pricing;
      const total =
        (pricing.words > 0 ? words / pricing.words : 0) * pricing.coins;

      setCoins(total.toFixed(2));
    };

    const selectedOutput = async (hash) => {
      await store.api.post(`/chat/message/${hash}`, {
        chat: selectedChat?.chat?._id,
      });
    };

    const onSaveMerge = async () => {
      setSaving(true);
      try {
        await selectedOutput(mergeItem._id);
        await store.api.post(`/merge/save`, {
          hash: selectedChat?.chat?._id,
          merge: mergeItem._id,
        });

        setSaving(false);
        updateChat({ ...mergeItem, hidden: false });
      } catch (error) {
        console.error(error);
        setSaving(false);
        toast.error('Failed to saving merge');
      }
    };

    const updateChat = (newItem) => {
      const index = selectedChat?.chat?.messages.findIndex(
        (item) => item == itemMerging
      );
      const newMessages = selectedChat.chat.messages;
      const item = newMessages[index].map((e) => {
        let modifiedItem = { ...e, selected: false };
        if (messages.includes(modifiedItem._id)) {
          const eleHighlights = {
            highlightRef: mergeItem._id,
            fragments: highlights[modifiedItem._id],
          };
          modifiedItem.highlights = modifiedItem.highlights
            ? [...modifiedItem.highlights, eleHighlights]
            : [eleHighlights];
        }
        return modifiedItem;
      });
      newMessages[index] = [...item, { ...newItem, selected: true }];
      const updatedChat = {
        ...selectedChat.chat,
        messages: newMessages,
      };

      addEvent(SAVE_MERGE, [updatedChat, newItem._id]);
      setActive(false);
    };

    const getLeftButton = () => {
      switch (activeTab) {
        case MERGE_STATES.HIGHLIGHT:
          return <div className="w-[120.65px]"></div>;
        case MERGE_STATES.OPERATION:
          return (
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<ArrowBackIcon />}
              onClick={handlePrevious}
              sx={{
                padding: '8px 27px',
                height: '40px',
                gap: '10px',
                borderColor: '#FCE4D6',
                backgroundColor: '#FCE4D6',
              }}
            >
              Previous
            </Button>
          );
        case MERGE_STATES.MERGE:
          return (
            <Button
              variant="outlined"
              color="secondary"
              disabled={merging || typing || saving}
              startIcon={<AddCircleOutlineIcon />}
              onClick={newMerge}
              sx={{
                padding: '8px 27px',
                height: '40px',
                gap: '10px',
                borderColor: '#FCE4D6',
                backgroundColor: '#FCE4D6',
                '&.Mui-disabled': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              New merge
            </Button>
          );
      }
    };

    const getRightButton = () => {
      switch (activeTab) {
        case MERGE_STATES.HIGHLIGHT:
          return (
            <Button
              variant="contained"
              color="secondary"
              startIcon={<ArrowForwardIcon />}
              onClick={() => setActiveTab(MERGE_STATES.OPERATION)}
              disabled={!consditionsByTab()}
              sx={{
                padding: '8px 27px',
                height: '40px',
                gap: '10px',
              }}
              disableElevation
            >
              Next
            </Button>
          );
        case MERGE_STATES.OPERATION:
          return (
            <Button
              variant="contained"
              color="secondary"
              startIcon={<ArrowForwardIcon />}
              onClick={onMerge}
              sx={{
                padding: '8px 27px',
                height: '40px',
                gap: '10px',
              }}
              disableElevation
            >
              Merge!
            </Button>
          );
        case MERGE_STATES.MERGE:
          return (
            <Button
              variant="contained"
              color="secondary"
              startIcon={<CheckCircleOutlineIcon />}
              disabled={saving}
              onClick={onSaveMerge}
              sx={{
                padding: '8px 27px',
                height: '40px',
                gap: '10px',
              }}
              disableElevation
            >
              Done
            </Button>
          );
      }
    };

    const handleCustomPrompt = (value) => {
      if (selectedTool == 'custom') {
        calculateCoins(value);
      }
    };

    const formValues = useMemo(() => {
      return {
        responses: formattedHighlights,
      };
    }, [formattedHighlights]);

    return (
      <>
        <motion.div
          initial={{ y: -400 }}
          animate={{ y: active ? 0 : -400 }}
          transition={{ type: 'spring', damping: 20, stiffness: 120 }}
          className="font-figtree z-50 rounded-b-[20px] w-full shadow-[0px_0px_10.7px_2px_rgba(0,0,0,0.25)] bg-seasalt dark:bg-jet"
        >
          <div
            className={`${isMinimized ? 'cursor-pointer' : ''}`}
            onClick={() => isMinimized && handleMinimize()}
          >
            <div className="px-6 py-5 relative">
              <div
                className={cn('absolute top-0 left-0 w-full h-[50%]', {
                  'bg-seasalt bg-gradient-to-b from-peachy-maroney/15 to-peachy-maroney/0':
                    theme === 'light',
                  'bg-jet bg-gradient-to-b from-peachy-maroney/15 to-peachy-maroney/0':
                    theme === 'dark',
                })}
              ></div>

              <div className="relative z-50">
                {getHeader()}

                <AnimatePresence>
                  {!isMinimized && (
                    <motion.div
                      className="origin-top"
                      key="expandable-content"
                      variants={containerVariants}
                      initial="minimized"
                      animate="expanded"
                    >
                      {/* Tab Content */}
                      {getTab().content}

                      {/* Prompt */}
                      <div
                        className={
                          (showPrompt || selectedTool == 'custom') &&
                          activeTab == MERGE_STATES.OPERATION
                            ? 'block'
                            : 'hidden'
                        }
                      >
                        <ViewPromptSection
                          ref={viewPromptRef}
                          selectedTool={tool}
                          promptValue={tool.prompt_values?.value}
                          formValues={formValues}
                          fullScreen={false}
                          borderRadious={'10px'}
                          color={'secondary'}
                          checkSmartMode={false}
                          allowCopy
                          disabled={selectedTool !== 'custom'}
                          onPromptChange={handleCustomPrompt}
                        />
                      </div>

                      {/* Navigation Buttons */}
                      <div className="w-full flex justify-between mt-6">
                        {getLeftButton()}
                        <div className="flex-1 flex justify-center">
                          {activeTab == MERGE_STATES.MERGE && !merging && (
                            <Button
                              variant="outlined"
                              color="secondary"
                              disabled={typing || saving}
                              startIcon={<CachedIcon />}
                              onClick={() => onMerge(true)}
                              sx={{
                                padding: '8px 27px',
                                height: '40px',
                                gap: '10px',
                                borderColor: '#FCE4D6',
                                backgroundColor: '#FCE4D6',
                                '&.Mui-disabled': {
                                  backgroundColor: 'transparent',
                                },
                              }}
                            >
                              Regenerate
                            </Button>
                          )}
                          <div className="flex gap-1.5 items-center justify-center text-center text-cool-gray px-2.5">
                            <span>COST: </span>
                            <CurrencyDollarIcon className="w-4 h-4" />
                            <span>{coins}</span>
                          </div>
                        </div>
                        {getRightButton()}
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
          </div>
        </motion.div>
        {showModelsDialog && (
          <ModelDialog
            selectedModels={[]}
            onInteraction={(models, newModels) => {
              setModel(models[0]);
              setShowModelsDialog(false);
            }}
            onClose={() => setShowModelsDialog(false)}
            maxModels={1}
            noCapabilities
            noDisclaimer
          />
        )}
      </>
    );
  })
);
export default SmartMergeDialog;

const TypeWriter = ({
  content,
  onTypingDone,
  onCharacterTyped,
  typingSpeed = 7,
}) => {
  const [displayText, setDisplayText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < content.length) {
      const timer = setTimeout(() => {
        setDisplayText(content.slice(0, currentIndex + 1));
        setCurrentIndex((prev) => prev + 1);
        onCharacterTyped?.();
      }, typingSpeed);

      return () => clearTimeout(timer);
    } else {
      onTypingDone?.();
    }
  }, [content, currentIndex, typingSpeed, onTypingDone, onCharacterTyped]);

  return (
    <MemoizedReactMarkdown
      className="prose break-words dark:prose-invert prose-p:leading-relaxed markdown prose-pre:p-0 text-base"
      remarkPlugins={[remarkGfm, remarkMath]}
      rehypePlugins={[rehypeRaw]}
      components={MarkdownComponents}
    >
      {displayText}
    </MemoizedReactMarkdown>
  );
};
