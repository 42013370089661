/* eslint-disable no-console */
import useChatStore from 'Components/Chat/store';
import { MAX_MODELS_SELECTION } from 'Constants/models';
import CommonModal from 'Features/CreateTemplatesForm/Components/CommonModal';
import CapabilitiesContainer from 'Features/ModelsModal/components/capabilitiesContainer';
import FiltersContainer from 'Features/ModelsModal/components/filtersContainer';
import ModelsContainer from 'Features/ModelsModal/components/modelsContainer';
import TabsContainer from 'Features/ModelsModal/components/tabs';
import useCommonFunctionsModels from 'Features/ModelsModal/hooks/useCommonFunctionsModels';
import useModelsAndCapabilities from 'Features/ModelsModal/hooks/useModelsAndCapabilities';
import useModelsModalStore from 'Features/ModelsModal/store';
import { inject, observer } from 'mobx-react';
import toast from 'react-hot-toast';

const ModelsModal = ({
  setSelectedModels,
  selectedCapabilities,
  onCapabilities,
  store,
  open,
  onClose,
}) => {
  // COMMON MODAL STORE
  const { layout, tab, favorites, setRefetch, refetch } = useModelsModalStore();

  // CHAT STORE
  const { setModelsList, addResponseModels, selectedModels } = useChatStore(
    (state) => state
  );

  // GET MODELS AND CAPABILITIES DATA
  const { models, capabilities, isLoading, setModels } =
    useModelsAndCapabilities({
      store,
      setModelsList,
    });

  // COMMON FUNCTIONS
  const {
    isSelected,
    disabled,
    selected,
    isActive,
    getCompability,
    getIcon,
    getUncompability,
  } = useCommonFunctionsModels({
    capabilities,
    modelsSelected: selectedModels,
    capabilitiesSelected: selectedCapabilities,
    addResponseModels,
  });

  const handleSelectModel = (model) => {
    if (isSelected(model)) {
      const models = selectedModels.filter((item) => item._id !== model._id);
      if (models.length > 0) {
        setSelectedModels(models);
      } else {
        toast('You must have at least one model selected', {
          icon: '⚠️',
        });
      }
    } else {
      if (selectedModels.length < MAX_MODELS_SELECTION) {
        setSelectedModels([...selectedModels, model]);
      } else {
        toast('You only can select up to 4 LLMs simultaneously', {
          icon: '⚠️',
        });
      }
    }
  };

  const handleSelectCapability = (capability) => {
    if (isActive(capability)) {
      const capabilities = selectedCapabilities?.filter(
        (item) => item._id !== capability._id
      );
      onCapabilities(capabilities);
    } else {
      onCapabilities([...selectedCapabilities, capability]);
    }
  };

  const onStaredLocal = (model) => {
    if (model.favorite) {
      const modelsArray = models.map((item) => {
        if (item._id === model._id) {
          return { ...item, favorite: false };
        }
        return item;
      });
      setModels(modelsArray);
    } else {
      const modelsArray = models.map((item) => {
        if (item._id === model._id) {
          return { ...item, favorite: true };
        }
        return item;
      });
      setModels(modelsArray);
    }
  };

  const handleToggleStar = async (model) => {
    onStaredLocal(model);
    try {
      if (!model.favorite) {
        await store.api.post('/v2/models/favorite', { modelId: model._id });
        toast.success('Model added to favorites');
      } else {
        await store.api.delete(`/v2/models/favorite/${model._id}`);
        toast.success('Model removed from favorites');
      }
      if (favorites) {
        setRefetch(refetch ? 0 : 1);
      }
    } catch (error) {
      console.error('Error adding model to favorites:', error);
      toast.error('Error adding model to favorites');
    }
  };

  // THIS IS THE RENDER OF THE TABS
  const TabPaneRender = {
    0: (
      <ModelsContainer
        layout={layout}
        models={models}
        selected={selected}
        disabled={disabled}
        handleChange={handleSelectModel}
        isLoading={isLoading}
        handleToggleStar={handleToggleStar}
      />
    ),
    1: (
      <CapabilitiesContainer
        getIcon={getIcon}
        isActive={isActive}
        capabilities={capabilities}
        getCompability={getCompability}
        modelsSelected={selectedModels}
        getUncompability={getUncompability}
        handleCapabilities={handleSelectCapability}
      />
    ),
  };

  return (
    <CommonModal
      noPaddingX
      open={open}
      maxWidth="xl"
      fullWidth
      maxHeight="100%"
      withCloseButton
      handleClose={onClose}
    >
      <div className="p-7 w-full overflow-hidden flex flex-col font-figtree h-[90dvh]">
        <div className="flex gap-2 items-center text-raisin-black dark:text-crystal-bell mb-4">
          <h1 className="font-bold text-3xl ">Select prompt feature</h1>
          {tab === 0 && (
            <div
              className={`flex flex-col justify-center text-xs font-normal px-4 sm:px-6`}
            >
              <ul className="list-disc space-y-2">
                <li className="ml-5">
                  <span className="-ml-1">
                    Select your default LLM for all your chats{' '}
                    <a
                      className="font-semibold text-nue-blue underline"
                      href="https://staging.straico.com/user-settings"
                      target="_blank"
                      rel="noreferrer"
                    >
                      here
                    </a>{' '}
                    or delve deeper into our LLM options{' '}
                    <a
                      className="font-semibold text-nue-blue underline"
                      href="https://straico.com/multimodel/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      here
                    </a>
                  </span>
                </li>
                <li className="ml-5 mt-1">
                  <span className="-ml-1">
                    You can select up to 4 LLMs simultaneously
                  </span>
                </li>
              </ul>
            </div>
          )}
        </div>
        {/* TABS */}
        <TabsContainer />
        {/* Filters */}
        {tab === 0 && (
          <>
            <FiltersContainer />
          </>
        )}
        {/* Render PANE TAB */}
        {TabPaneRender[tab]}
      </div>
    </CommonModal>
  );
};

export default inject('store')(observer(ModelsModal));
