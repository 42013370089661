import { createTheme } from '@mui/material';

export const themeOptions = (mode = 'light') => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          // palette values for light mode
          primary: {
            main: '#464BBA', // nue-blue
          },
          secondary: {
            main: '#F2A474',
          },
          toogle: {
            main: '#B5BA6E',
          },
          text: {
            primary: '#211E21',
            secondary: '#A1A1A0',
            disabled: '#A1A1A0',
          },
          background: {
            default: '#F7F7F8', // seasalt
            paper: '#F7F7F8',
          },
        }
      : {
          // palette values for dark mode
          primary: {
            main: '#464BBA',
          },
          secondary: {
            main: '#F2A474',
          },
          toogle: {
            main: '#B5BA6E',
          },
          background: {
            default: '#121212',
            paper: '#141114',
          },
          text: {
            primary: '#fff',
            secondary: '#A1A1A0',
            disabled: '#A1A1A0',
          },
        }),
  },
  typography: {
    fontFamily: ['Figtree', 'sans-serif'].join(','),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        root: {
          fontFamily: '"Figtree", sans-serif',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontFamily: '"Figtree", sans-serif',
          fontWeight: 600,
          lineHeight: '24px',
          padding: '8px 16px',
          borderRadius: '12px',
          color:
            ownerState.variant === 'contained'
              ? ownerState.color === 'inherit'
                ? '#A1A1A0 '
                : '#fff'
              : ownerState.color,
          textTransform: 'initial',
          '&.MuiButton-containedSecondary:hover': {
            backgroundColor: 'rgb(222 105 52 / 80%)',
          },
        }),
        contained: {},
      },
      variants: [
        {
          props: { variant: 'filled' },
          style: {
            color: mode === 'light' ? '#5256A6' : '#EFEFEF',
            backgroundColor: mode === 'light' ? '#D4D5E6' : '#7F82C3',
            '&:hover': {
              backgroundColor: '#D4D5E6B2',
            },
          },
        },
      ],
    },
    typography: {
      fontFamily: ['Figtree', 'sans-serif'].join(','),
    },
  },
});

export const customTheme = createTheme(themeOptions);
