import useThemeStore from 'Theme/store';

const Skeleton = () => {
  const { theme } = useThemeStore();

  const skeletonStyle = (width, height, delay) => {
    const gradient =
      theme === 'dark'
        ? 'linear-gradient(100deg, #474549 20%, rgba(37, 36, 37, 0) 50%, #474549 60%)'
        : 'linear-gradient(100deg, #e8e8e8 20%, rgba(247, 247, 248, 0) 50%, #e8e8e8 60%)';
    const backgroundColor =
      theme === 'dark' ? 'rgba(37, 36, 37, 0)' : 'rgba(247, 247, 248, 0)';

    return {
      width,
      height,
      borderRadius: '2px',
      display: 'inline-block',
      backgroundColor,
      backgroundSize: '1000px 1000px',
      backgroundImage: gradient,
      animation: `springIn 0.8s cubic-bezier(0.34, 1.56, 0.64, 1) ${delay} forwards, placeholderShimmer 1.5s linear infinite ${delay}`,
    };
  };

  return (
    <>
      <style>
        {`
      @keyframes springIn {
        0% {
          transform: scaleX(0);
          transform-origin: left;
        }
        60% {
          transform: scaleX(1.2);
          transform-origin: left;
        }
        100% {
          transform: scaleX(1);
          transform-origin: left;
        }
      }

      @keyframes placeholderShimmer {
        0% {
          background-position: -500px 0;
        }
        100% {
          background-position: 500px 0;
        }
      }
    `}
      </style>
      <div className="max-w-full w-full" style={{ borderColor: '#5256A6' }}>
        <div className="flex-1 space-y-4">
          <div className="flex gap-4">
            <span
              className="skeleton"
              style={skeletonStyle('60%', '10px', '0s')}
            ></span>
            <span
              className="skeleton"
              style={skeletonStyle('40%', '10px', '0.3s')}
            ></span>
          </div>
          <span
            className="skeleton"
            style={skeletonStyle('80%', '10px', '0.6s')}
          ></span>
        </div>
      </div>
    </>
  );
};

export default Skeleton;
