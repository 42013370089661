import { useOutlet } from 'react-router-dom';
import useThemeStore from 'Theme/store';
// Components
import Header from './Components/Header';
// Icons
import StraicoSymbolIcon from '../../Theme/icons/straicoSymbol';

const PublicLayout = () => {
  const outlet = useOutlet();
  const { theme } = useThemeStore();

  return (
    <div className="h-full w-full flex">
      <div
        className={`h-full z-50 pt-5 flex flex-col items-center ${
          theme == 'dark' ? 'bg-umbra' : 'bg-ghost-white'
        } w-14 gap-4`}
      >
        <div className="flex items-center justify-between px-5">
          <a
            href="https://staging.straico.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StraicoSymbolIcon className="h-10 fill-nue-blue cursor-pointer select-none duration-500" />
          </a>
        </div>
      </div>
      <div className="flex-1 flex flex-col">
        <Header />
        <div
          className={`flex-1 flex flex-col overflow-auto overflow-rtl ${
            theme == 'dark' ? 'overflow-rtl-dark' : 'overflow-rtl-light'
          }`}
        >
          {outlet}
        </div>
      </div>
    </div>
  );
};

export default PublicLayout;
