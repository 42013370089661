/* eslint-disable no-prototype-builtins */
import axios from 'axios';
import Filter from 'bad-words';
import { configure, makeObservable, observable } from 'mobx';

import * as solidIcons from '@heroicons/react/24/solid';
import { get } from 'lodash';

import io from 'socket.io-client';

import config from './config';
import { toastReward } from './utils/toastCall';

import { isMobile, isTablet, isDesktop } from 'react-device-detect';
import { UAParser } from 'ua-parser-js';

let filterBadWords = new Filter();

let baseURL = config.baseURL;

configure({ enforceActions: 'never' });

export let api = axios.create({ baseURL });

class appStore {
  api = api;
  // socket = socket
  @observable baseURL = baseURL;
  @observable redirect = ``;
  @observable editor;
  @observable editorIsLoading = true;

  // User Profile
  @observable profile = {};
  @observable isLoggedIn = false;
  @observable loginLoading = false;
  // Fpr
  @observable fpr = null;

  @observable labelSection = '';

  // @observable socketUserId = null

  @observable landingPageUrl = config.landingPageUrl;

  @observable.ref tools = [];
  @observable.ref TOOLS = [];

  @observable showSectionMedia = true;
  @observable showSectionMyPrompts = true;
  @observable showSectionBussMgmt = true;
  @observable showSectionMarketing = true;
  @observable showSectionLangWriti = true;
  @observable showSectionProgramming = true;
  @observable showSectionFreestyle = true;
  @observable showSectionPersonal = true;

  // Socket io variables
  @observable sioAuth = null;
  @observable sioClient = null;
  @observable subscribedRooms = [];

  constructor() {
    makeObservable(this);
    this.init();
    this.cachedRewardResponse = null;
    this.cachedFavoritesResponse = null;
    this.cachedModelsResponse = null;
    this.isFetchingModels = false;
    this.isApplyingReward = false;
    this.isFetchingFavorites = false;
    this.cachedTemplatesWithFoldersResponse = null;
    this.isFetchingTemplatesWithFolders = false;
    // Check credits every time, and log out people who aren't authenticated
    this.api.interceptors.response.use(
      (response) => {
        const autocomplete = !response.config.url.includes('/tool');
        if (!response.config.url.includes('/reward/apply') && autocomplete) {
          this.applyRewards();
        }
        this.updateCredits(response);
        return response;
      },
      (error) => {
        if (
          error.response &&
          error.response.statusText === 'Token Authentication Failed'
        ) {
          this.handleLogout();
        }
        if (
          error.response &&
          error.response.statusText === 'No Credit Remaining'
        ) {
          this.noCreditsRemainPrompt();
        }
        return Promise.reject(error);
      }
    );
  }

  noCreditsRemainPrompt = () => {
    // set the browser url to the no-credits page
    window.location.pathname = '/my-profile';
  };

  init = async () => {
    try {
      this.referralTrackingCode();
      const profile = localStorage.getItem('profile');
      const token = localStorage.getItem('token');
      if (profile && token) {
        this.api.defaults.headers.common['x-access-token'] = token;
        this.profile = JSON.parse(profile);
        this.isLoggedIn = true;

        this.refreshTokenAndProfile();
        this.getToolsFromMongo();
        this.getClient();
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  @observable referral = '';

  referralTrackingCode = async () => {
    let referral = new URLSearchParams(window.location.search).get('referral');
    if (referral) {
      this.setReferral(referral);
    } else {
      this.initReferral();
    }
  };

  setReferral = async (referral) => {
    this.referral = referral;
    localStorage.setItem('referral', JSON.stringify(referral));
  };

  setFpr = (fpr) => {
    this.fpr = fpr;
  };

  initReferral = async () => {
    const referral = localStorage.getItem('referral');
    this.referral = referral;
  };

  loginWithDataTokenAndProfile = async (data) => {
    if (data) {
      this.setToken(data?.token);
      this.setProfile(data?.profile);
      this.getToolsFromMongo();
      this.isLoggedIn = true;
    }
  };

  refreshTokenAndProfile = async () => {
    try {
      let data = await this.api
        .post('/user/refresh/profile')
        .then(({ data }) => data);
      if (data) {
        this.setProfile(data.profile);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      this.handleLogout();
    }
  };

  refreshTokenAndProfileCoins = async () => {
    try {
      let data = await this.api
        .post('/user/refresh/coins')
        .then(({ data }) => data);
      if (data) {
        this.setProfile({ ...this.profile, ...data.profile });
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      this.handleLogout();
    }
  };

  setToken = async (token) => {
    this.api.defaults.headers.common['x-access-token'] = token;
    localStorage.setItem('token', token);
  };

  setProfile = async (profile) => {
    this.profile = profile;
    localStorage.setItem('profile', JSON.stringify(profile));
  };

  handleLogout = () => {
    localStorage.clear();
    caches.keys().then((names) => {
      for (let name of names) caches.delete(name);
    });
    this.isLoggedIn = false;
    this.profile = {};
    this.api.defaults.headers.common['x-access-token'] = '';
    localStorage.removeItem('token');
    localStorage.removeItem('profile');
    localStorage.removeItem('tools');
    // this.socket.disconnect()
  };

  @observable toolsKeyword = '';
  onChangeToolsKeyword = async (e) => {
    this.toolsKeyword = e.target.value;
    this.tools = await this.filterTools();
  };

  filterTools = async () => {
    const response = await this.api.get('/v2/templates/with-folders', {
      params: { keyword: this.toolsKeyword },
    });
    return response.data?.data?.tools;
  };

  setTools = (items) => {
    this.TOOLS = items.map((tool) => {
      const Icon = get(solidIcons, tool.Icon);
      tool.Icon = Icon;
      return tool;
    });
    this.tools = this.TOOLS;
  };

  getTools = () => {
    let list = JSON.parse(localStorage.getItem('tools'));
    this.TOOLS = list.map((tool) => {
      const Icon = get(solidIcons, tool.Icon);
      tool.Icon = Icon;
      return tool;
    });
    this.tools = this.TOOLS;
  };

  setNewTool = (item) => {
    this.getTools();
    this.tools.push(item);
    this.setTools(this.tools);
  };

  getToolsFromMongo = async () => {
    const response = await this.api.get('/v2/templates/with-folders');
    this.setTools(response.data?.data?.tools);
  };

  getToolByTitle = (title) => {
    return this.tools.find((tool) => tool.title === title);
  };

  getToolByUrl = (url) => {
    this.getTools();
    return this.tools.find((tool) => tool.to === url);
  };

  @observable error = '';
  checkPrompt = ({ value, attr }) => {
    if (filterBadWords.isProfane(value)) {
      // eslint-disable-next-line no-throw-literal
      throw {
        success: false,
        attr,
        value: value.replace(/^\s+|\s+$/g, ''),
        message: 'Unsafe content detected, please try different language',
      };
    }
    if (value) {
      return {
        success: true,
        attr,
        value: value.replace(/^\s+|\s+$/g, ''),
      };
    }
  };
  checkOutput = (output) => {
    if (output) {
      return output.replace(/^\s+|\s+$/g, '');
    }
    return '';
  };

  fetchFavorites = async () => {
    if (this.isFetchingFavorites) return;
    if (this.cachedFavoritesResponse) {
      return this.cachedFavoritesResponse;
    }

    this.isFetchingFavorites = true;
    try {
      const response = await this.api.get('/v2/templates/favorites');
      const data = response.data;
      this.cachedFavoritesResponse = data;
      return data;
    } catch (err) {
      console.error('Error al obtener herramientas favoritas:', err);
      throw err;
    } finally {
      this.isFetchingFavorites = false;
    }
  };

  fetchModels = async () => {
    if (this.isFetchingModels) return;
    if (this.cachedModelsResponse) {
      return this.cachedModelsResponse;
    }

    this.isFetchingModels = true;
    try {
      const response = await this.api.get('/model');
      const data = response.data;
      this.cachedModelsResponse = data;
      return data;
    } catch (err) {
      console.error('Error al obtener modelos:', err);
      throw err;
    } finally {
      this.isFetchingModels = false;
    }
  };

  fetchTemplatesWithFolders = async (keyword = '') => {
    if (this.isFetchingTemplatesWithFolders) return;
    if (this.cachedTemplatesWithFoldersResponse) {
      return this.cachedTemplatesWithFoldersResponse;
    }

    this.isFetchingTemplatesWithFolders = true;
    try {
      const response = await this.api.get('/v2/templates/with-folders', {
        params: { keyword: keyword.replace('/', '') },
      });
      const data = response.data;
      this.cachedTemplatesWithFoldersResponse = data;
      return data;
    } catch (err) {
      console.error('Error al obtener templates con folders:', err);
      throw err;
    } finally {
      this.isFetchingTemplatesWithFolders = false;
    }
  };

  callServiceApplyReward = async () => {
    if (this.isApplyingReward) return;
    this.isApplyingReward = true;
    try {
      const response = await this.api.post('/reward/apply');
      const data = response.data;
      await localStorage.setItem('totalRewards', data.totalRewards);
      if (data.rewardApplied) {
        // Update the credits
        const { credits, creditsUsed, creditsPrevious } = data.userInfo;
        this.profile.credits = credits;
        this.profile.creditsUsed = creditsUsed;
        this.profile.creditsPrevious = creditsPrevious;
        // Generate the toast
        data.rewardMessages.forEach((message) => {
          toastReward({
            title: "You've won free coins",
            message: message,
            linkText: 'See my rewards',
            linkUrl: '/rewards',
            image: '/gifs/straico-bot-animated.gif',
            time: 15000,
          });
        });
      }
      this.cachedRewardResponse = data;
    } catch (err) {
      console.log(err);
    } finally {
      this.isApplyingReward = false;
    }
  };

  applyRewards = async () => {
    if (this.isLoggedIn) {
      const totalRewards = await localStorage.getItem('totalRewards');
      if (this.profile.winRewards == Number(totalRewards)) {
        return;
      }
      if (
        totalRewards !== null &&
        this.profile.winRewards < Number(totalRewards)
      ) {
        if (this.cachedRewardResponse) {
          this.handleRewardResponse(this.cachedRewardResponse);
        } else {
          this.callServiceApplyReward();
        }
      } else {
        if (this.cachedRewardResponse) {
          this.handleRewardResponse(this.cachedRewardResponse);
        } else {
          this.callServiceApplyReward();
        }
      }
    }
  };
  handleRewardResponse = (data) => {
    if (data.rewardApplied) {
      // Update the credits
      const { credits, creditsUsed, creditsPrevious } = data.userInfo;
      this.profile.credits = credits;
      this.profile.creditsUsed = creditsUsed;
      this.profile.creditsPrevious = creditsPrevious;
      // Generate the toast
      data.rewardMessages.forEach((message) => {
        toastReward({
          title: "You've won free coins",
          message: message,
          linkText: 'See my rewards',
          linkUrl: '/rewards',
          image: '/gifs/straico-bot-animated.gif',
          time: 15000,
        });
      });
    }
  };

  updateCredits = async (data) => {
    try {
      // console.log(data)
      if (data.hasOwnProperty('data')) {
        if (data.data.hasOwnProperty('credits')) {
          this.profile.credits = data.data.credits;
        }
        if (data.data.hasOwnProperty('creditsUsed')) {
          this.profile.creditsUsed = data.data.creditsUsed;
        }
        if (data.data.hasOwnProperty('creditsPrevious')) {
          this.profile.creditsPrevious = data.data.creditsPrevious;
        }
      } else {
        if (data.hasOwnProperty('credits')) {
          this.profile.credits = data.credits;
        }
        if (data.hasOwnProperty('creditsUsed')) {
          this.profile.creditsUsed = data.creditsUsed;
        }
        if (data.hasOwnProperty('creditsPrevious')) {
          this.profile.creditsPrevious = data.creditsPrevious;
        }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  @observable copyToClipboardText = ``;
  copyToClipboard = (output) => {
    if (output instanceof Array) {
      output = output.join('\n');
    }
    if (!navigator.clipboard) {
      let textarea = document.getElementById('copy-textarea');
      this.copyToClipboardText = `${output}`;
      textarea.focus();
      textarea.select();
      document.execCommand('copy');
      return;
    }
    navigator.clipboard.writeText(output).then(
      function () {
        // eslint-disable-next-line no-console
        console.log('Async: Copying to clipboard was successful!');
      },
      function (err) {
        // eslint-disable-next-line no-console
        console.error('Async: Could not copy text: ', err);
      }
    );
  };

  @observable apiUrl = ``;
  @observable category = ``;
  @observable subcategory = ``;
  @observable prompt = ``;
  @observable feedback = ``;
  reportToFeedback = (output, api, category, subcategory, prompt) => {
    this.redirect = '/my-profile/feedback';
    this.feedback = `${output}`;
    this.apiUrl = `${api}`;
    this.category = category;
    this.subcategory = subcategory;
    this.prompt = prompt;
    setTimeout(() => {
      this.redirect = '';
    }, 50);
  };

  handleDownload = async (url) => {
    var element = document.createElement('a');
    element.href = url;
    element.download = url.split('/').pop();
    element.click();
    element.remove();
  };

  // Socket io functions
  getClient = () => {
    if (process.env.REACT_APP_SOCKET_IO_ENABLED == 1) {
      if (!this.sioAuth) this.generateSioAuth();
      if (this.sioClient) return this.sioClient;

      this.sioClient = io(config.socketURL, {
        extraHeaders: this.sioAuth,
      });

      this.sioClient.on('connect', this.resubscribe.bind(this));

      this.sioClient.on('connect_error', function (error) {
        // eslint-disable-next-line no-console
        console.error('connect error', error);
      });
      this.sioClient.on('connect_timeout', function (timeout) {
        // eslint-disable-next-line no-console
        console.error('connect timeout', timeout);
      });
      this.sioClient.on('error', function (error) {
        // eslint-disable-next-line no-console
        console.error('error', error);
      });

      return this.sioClient;
    } else {
      return null;
    }
  };

  generateSioAuth = () => {
    const token = localStorage.getItem('token');
    this.sioAuth = token ? { 'x-access-token': token } : null;
  };

  listenEvent = (eventName, callback) => {
    var socket = this.getClient();
    if (socket) {
      var refresh = function (msg) {
        callback(msg);
      };
      socket.on(eventName, refresh);
    }
  };

  removeListener = (eventName, callback) => {
    var socket = this.getClient();
    if (socket) {
      var refresh = function (msg) {
        callback(msg);
      };
      socket.off(eventName, refresh);
    }
  };

  stopStreamChat(chatId) {
    var socket = this.getClient();
    if (socket) {
      socket.emit('emitToRoom', {
        room: 'server',
        event: 'stopStream',
        data: { chatId },
      });
    }
  }

  closeConnection() {
    var socket = this.getClient();
    if (socket) {
      socket.close();
    }
  }

  sendSubscribe = async (room) => {
    if (process.env.REACT_APP_SOCKET_IO_ENABLED == 1) {
      const response = await this.api.post(config.socketURL + '/subscribe', {
        room,
      });
      return response;
    } else {
      return null;
    }
  };

  emit = async (sioEmitter) => {
    if (process.env.REACT_APP_SOCKET_IO_ENABLED == 1) {
      await this.api.post(config.socketURL + '/emit', sioEmitter);
    }
    // console.log(response)
  };

  subscribeRoom = (room) => {
    // console.log('------------');
    // console.log(room);
    // console.log('------------');
    var socket = this.sioClient;
    if (!this.sioClient) {
      socket = this.getClient();
    }

    if (socket) {
      var idx = this.subscribedRooms.indexOf(room);
      if (idx !== -1) return;
      this.subscribedRooms.push(room);

      // console.log('SUBSCRIBE ROOM')
      if (!socket.connected) return;
      this.sendSubscribe(room);
    }
  };

  resubscribe = () => {
    // console.log('On.connect')
    if (this.subscribedRooms.length)
      this.sendSubscribeMultiple(this.subscribedRooms);
  };

  unsubscribeRoom = async (room) => {
    if (process.env.REACT_APP_SOCKET_IO_ENABLED == 1) {
      var idx = this.subscribedRooms.indexOf(room);
      if (idx === -1) return;
      this.subscribedRooms.splice(idx, 1);
      const response = await this.api.put(config.socketURL + '/unsubscribe', {
        room,
      });
      return response;
    } else {
      return null;
    }
  };

  unsubscribeRooms = async (rooms) => {
    if (process.env.REACT_APP_SOCKET_IO_ENABLED == 1) {
      rooms.forEach((room) => {
        var idx = this.subscribedRooms.indexOf(room);
        if (idx === -1) {
          return;
        }
        this.subscribedRooms.splice(idx, 1);
      });
      const response = await this.api.put(
        config.socketURL + '/unsubscribe-multiple',
        {
          rooms,
        }
      );
      return response;
    } else {
      return null;
    }
  };

  clearSubscriptions = async () => {
    if (process.env.REACT_APP_SOCKET_IO_ENABLED == 1) {
      const response = await this.api.put(
        config.socketURL + '/unsubscribe-multiple',
        {
          rooms: this.subscribedRooms,
        }
      );
      return response;
    } else {
      return null;
    }
  };

  sendSubscribeMultiple = async (rooms) => {
    if (process.env.REACT_APP_SOCKET_IO_ENABLED == 1) {
      const response = await this.api.post(
        config.socketURL + '/subscribe-multiple',
        {
          rooms,
        }
      );
      return response;
    } else {
      return null;
    }
  };

  countWords = (text) => {
    if (typeof text !== 'string') {
      return 0;
    }
    // eslint-disable-next-line no-control-regex
    let words = text.replace(/[^\x00-\xFF]/g, (c) => {
      const charCode = c.charCodeAt(0);

      // Removes ZERO WIDTH NO-BREAK SPACE and "General Punctation Chars"
      // http://www.unicodemap.org/range/40/General_Punctuation/
      if (charCode === 65279 || (charCode >= 0x2007 && charCode <= 0x206f)) {
        return '';
      }
      // Remove unicode spaces
      if (charCode === 0x00a0) {
        return '';
      }
      // hex charcode with 4 positions fixed(0 padding)
      return `0x${(charCode + 0x10000).toString(16).substr(-4).toUpperCase()}`;
    });

    words = words.replace(/&nbsp;/g, ''); // Remove &nbsp;
    words = words.replace(/\s\s+/g, ' '); // Remove double spaces
    words = words.replace(/(<[^>]*>)|([.,;:?!]+)/g, ' '); // HTML tags and punctuation

    // Removes other chars, except for "one letter words(Special Chars)"
    const charRegex =
      /\[.?\]|\{.?\}|([^\s\wàÀáÁåÅæÆèÈéÉÈíÍöÖøØőŐвВєЄжЖзЗиИйЙкКўЎяЯ]|_)/g;
    words = words.replace(charRegex, '').trim();

    if (words) {
      return words
        .split(/\s+/)
        .map((token) =>
          token.replace(/0x[0-9A-Fa-f]{4}/g, (c) => String.fromCharCode(+c))
        ).length;
    }

    return 0;
  };

  sendMetricsEvent = async (event, data = {}, details = {}) => {
    const deviceType = this.getDeviceType(); // 'mobile', 'tablet', 'desktop', 'unknown'

    const parser = new UAParser();
    const deviceResult = parser.getResult();

    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const screenSize = `${screenWidth}x${screenHeight}`;

    const eventDetails = {
      event: event,
      details: {
        device_info: {
          type: deviceType,
          screenSize: screenSize,
          ...deviceResult,
        },
        ...details,
      },
      ...data,
    };

    try {
      const appStore = window.store;
      const response = await appStore.api.post(`/metrics`, eventDetails);
      // console.log(response);
    } catch (error) {
      console.error(error);
    }
  };

  getDeviceType = () => {
    if (isMobile) return 'mobile';
    if (isTablet) return 'tablet';
    if (isDesktop) return 'desktop';
    return 'unknown';
  };
}

export default appStore;
