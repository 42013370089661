import { useDroppable } from '@dnd-kit/core';
import { cloneElement } from 'react';

const DroppableZone = ({ ele, index, dragOverlay, children }) => {
  const { setNodeRef, isOver } = useDroppable({
    id: ele._id ? ele._id : `drop-${index}`,
    data: { ele, index },
  });

  return (
    <div ref={setNodeRef} className="relative">
      {isOver && !dragOverlay?.isCombining && (
        <>
          <div class="absolute top-0 h-full w-1 -translate-x-1/2 duration-150 bg-nue-blue rounded"></div>
          <div class="absolute top-0 h-full -translate-x-1/2 duration-150 w-2 blur-md bg-nue-blue"></div>
        </>
      )}
      {cloneElement(children, { isOver, dragOverlay })}
    </div>
  );
};

export default DroppableZone;
